import React, { useState } from "react";
import * as XLSX from "xlsx";

const HeightRangeCalculator = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Export data to Excel with a tab (sheet) for each range
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();

    Object.entries(rangeSums).forEach(([range, sums]) => {
      const sheetData = [
        [
          "Product Name",
          "In Stock (m)",
          "In Production (m)",
          "In Delivery (m)",
          "Total (m)",
        ],
        ...sums.products.map((product) => [
          product.productName,
          parseFloat(product.inStock).toFixed(2),
          parseFloat(product.inProduction).toFixed(2),
          parseFloat(product.inDelivery).toFixed(2),
          parseFloat(product.total).toFixed(2),
        ]),
      ];

      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      worksheet["!cols"] = [
        { wch: 40 }, // Product Name
      ];

      const sheetName = range.replace("_", " - ");
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    // Generate current date and time for the file name
    const now = new Date();
    const date = now.toISOString().slice(0, 10); // YYYY-MM-DD
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS
    const fileName = `${date}_${time}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  // Define the height ranges
  const heightRanges = [
    { min: 90, max: 95 },
    { min: 85, max: 90 },
    { min: 80, max: 85 },
    { min: 75, max: 80 },
    { min: 70, max: 75 },
    { min: 65, max: 70 },
    { min: 60, max: 65 },
    { min: 55, max: 60 },
    { min: 50, max: 55 },
    { min: 45, max: 50 },
    { min: 40, max: 45 },
    { min: 35, max: 40 },
    { min: 30, max: 35 },
  ];

  // Initialize dictionaries to store the sum for each range and product details
  const rangeSums = heightRanges.reduce((acc, range) => {
    const rangeLabel = `${range.min}_${range.max}`;
    acc[rangeLabel] = {
      cantitate: 0,
      production: 0,
      totalInDelivery: 0,
      calculated: 0,
      products: [], // Added to store product details
    };
    return acc;
  }, {});

  // Perform calculations for each item in the data array
  data.forEach((item) => {
    // Skip items with "BIC TUBE" in `den_produs`
    if (item.den_produs && item.den_produs.toLowerCase().includes("bic tub")) {
      return;
    }

    const totalInDelivery =
      item.total_in_delivery / (item.total_boxes_or_pallets || 1);
    const cantitateValue = item.cantitate / (item.total_boxes_or_pallets || 1);
    const productionValue =
      item.total_in_production / (item.total_boxes_or_pallets || 1);

    const useDepth =
      item.den_produs_en &&
      item.den_produs_en.toLowerCase().includes("vee tir");

    const lengthInMeters =
      (useDepth
        ? item.quantity_dimensions_per_unit.height
        : item.quantity_dimensions_per_unit.length) / 100;

    const totalLengthVolumeCantitate = cantitateValue * lengthInMeters;
    const totalLengthVolumeProduction = productionValue * lengthInMeters;
    const totalLengthVolumeInDelivery = totalInDelivery * lengthInMeters;

    const totalLengthVolumeCalculated =
      totalLengthVolumeCantitate +
      totalLengthVolumeProduction +
      totalLengthVolumeInDelivery;

    const length = !useDepth
      ? item.quantity_dimensions_per_unit.depth
      : item.quantity_dimensions_per_unit.height * 2 + 6;

    for (const range of heightRanges) {
      if (length > range.min && length <= range.max) {
        const rangeLabel = `${range.min}_${range.max}`;
        rangeSums[rangeLabel].cantitate += totalLengthVolumeCantitate;
        rangeSums[rangeLabel].production += totalLengthVolumeProduction;
        rangeSums[rangeLabel].totalInDelivery += totalLengthVolumeInDelivery;
        rangeSums[rangeLabel].calculated += totalLengthVolumeCalculated;

        // Push product details into products array
        rangeSums[rangeLabel].products.push({
          productName: item.den_produs || "N/A",
          inStock: totalLengthVolumeCantitate.toFixed(2),
          inProduction: totalLengthVolumeProduction.toFixed(2),
          inDelivery: totalLengthVolumeInDelivery.toFixed(2),
          total: totalLengthVolumeCalculated.toFixed(2),
        });

        break;
      }
    }
  });

  // Toggle popup open/close state
  const togglePopup = () => setIsOpen(!isOpen);

  return (
    <div style={styles.wrapperMod}>
      <button onClick={togglePopup} style={styles.buttonOpen}>
        Show Width Range Calculations
      </button>

      {isOpen && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <h2>Width Range Calculations (in meters)</h2>
            <table style={styles.table}>
              <thead>
                <tr>
                  <th>Range</th>
                  <th>In Stock</th>
                  <th>In Production</th>
                  <th>In Delivery</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(rangeSums).map(([range, sums]) => (
                  <tr key={range}>
                    <td>{range.replace("_", " - ")}</td>
                    <td>{sums.cantitate.toFixed(2)} m</td>
                    <td>{sums.production.toFixed(2)} m</td>
                    <td>{sums.totalInDelivery.toFixed(2)} m</td>
                    <td>{sums.calculated.toFixed(2)} m</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button onClick={exportToExcel} style={styles.downloadButton}>
              Download Excel
            </button>
            <button onClick={togglePopup} style={styles.closeButton}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeightRangeCalculator;

// Styles
const styles = {
  wrapperMod: { display: "flex" },
  buttonOpen: {
    display: "flex",
    alignItems: "center",
    background: "#0958d9",
    color: "#fff",
    padding: "7px 15px",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  modal: {
    position: "fixed",
    zIndex: 10,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "500px",
    textAlign: "center",
  },
  closeButton: { marginTop: "20px" },
  downloadButton: {
    marginTop: "20px",
    background: "#28a745",
    color: "white",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    marginRight: "25px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
};
