import React from "react";
import calculateOrderBox from "../helpers/orderBoxCalcHelper";
import { calculatePackages } from "../helpers/packageCalculation";
import { calculateVolume } from "../helpers/volumeHelper";
import styled from "styled-components";
import * as XLSX from "xlsx";

const Wrap = styled.div`
  background: #0958d9;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid transparent;
  &:hover {
    cursor: pointer;
    background: #4096ff;
    border: 1px solid transparent;
  }
`;

export const ExportAllToExcel = (props) => {
  const { filteredData } = props;
  // console.log(JSON.stringify(filteredData[0]));

  const handleExportToExcel = () => {
    const exportData = filteredData
      .filter((e) => Number(e.minStock) - Number(e.cantitate) > 0)
      .sort((a, b) => {
        const nameA = a.den_produs_en || a.den_produs;
        const nameB = b.den_produs_en || b.den_produs;
        return nameA.localeCompare(nameB);
      })
      .map((item) => {
        console.log(
          "Number(e.minStock) - Number(e.cantitate)",
          Number(item.minStock) - Number(item.cantitate) > 0,
        );
        return {
          "Name product":
            item.den_produs_en !== "" ? item.den_produs_en : item.den_produs,
          "Stock (pcs)": item.cantitate,
          "Min. stock (pcs)": item.minStock,
          "Order (pcs)": isNaN(calculateOrderBox(item))
            ? item.id_produs === "00000"
              ? item.minStock
              : 0
            : calculateOrderBox(item),
          "Total Box/Roll packages": isNaN(calculatePackages(item))
            ? 0
            : calculatePackages(item),
          "Total Box/Roll (m3)":
            isNaN(calculateVolume(item, calculatePackages(item))) ||
            !calculateVolume(item, calculatePackages(item)) ||
            calculateVolume(item, calculatePackages(item)) < 0
              ? 0
              : calculateVolume(item, calculatePackages(item)),
          "Total weight (kg)": isNaN(
            calculatePackages(item) * item.weight_bax_pallet,
          )
            ? 0
            : calculatePackages(item) * item.weight_bax_pallet,
          "Date production": item.production_date,
          "Total in production": item.total_in_production,
          "Shipping day": item.delivery_date,
          "In delivery total": item.total_in_delivery,
          "Size per Box/Roll in cm: Length/Height/Depth": `${item.quantity_dimensions_per_unit?.length}/${item.quantity_dimensions_per_unit?.height}/${item.quantity_dimensions_per_unit?.depth}`,
          "Q/Box/Roll": item.total_boxes_or_pallets,
          "Weight box/roll": item.weight_bax_pallet,
          "Unit price": item.unitPrice,
        };
      });

    const ws = XLSX.utils.json_to_sheet(exportData);

    // Set width for the columns
    ws["!cols"] = [
      { wpx: 350 }, // Width for "Name product" column
      { wpx: 64 }, // Width for "Stock (pcs)" column
      { wpx: 64 }, // Width for "Min. stock (pcs)" column
      { wpx: 64 }, // Width for "Order (pcs)" column
      { wpx: 124 }, // Width for "Total Box/Roll packages" column
      { wpx: 101 }, // Width for "Total Box/Roll (m3)" column
      { wpx: 91 }, // Width for "Total weight (kg)" column
      { wpx: 120 }, // Width for "Date production" column
      { wpx: 120 }, // Width for "Total in production" column
      { wpx: 120 }, // Width for "Shipping day" column
      { wpx: 120 }, // Width for "In delivery total" column
      { wpx: 180 }, // Width for "Size per Box/Roll in cm: Length/Height/Depth" column
      { wpx: 62 }, // Width for "Q/Box/Roll" column
      { wpx: 90 }, // Width for "Weight box/roll" column
      { wpx: 64 }, // Width for "Unit price" column
    ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Stock Data");

    // Get current date and time for the file name
    const currentDate = new Date().toISOString().replace(/:/g, "-");
    const fileName = `stock_${currentDate}.xlsx`;

    // Save the Excel file with current date and time in the file name
    XLSX.writeFile(wb, fileName);
  };

  return <Wrap onClick={handleExportToExcel}>Export All to EXCEL</Wrap>;
};
